import { createSelector } from 'reselect';

export const selectComponentVersions = (state, componentId) => state.versions.map[componentId];

export const selectComponentVersionsList = createSelector(
  selectComponentVersions,
  // eslint-disable-next-line max-len
  componentVersions => componentVersions?.sort((a, b) => (new Date(b.name)) - (new Date(a.name)),
));

export const selectGetVersionsIsLoading = state => !!state.versions.isLoading.getVersions;

export const selectVersionsIsLoading = createSelector(
  [
    selectGetVersionsIsLoading,
  ],
  (...args) => args.some(v => v),
);
