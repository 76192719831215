import agent from 'services/http';
import { mapVersionFromServer } from './versions.mapper';

const e = encodeURIComponent;

/**
 * GET /api/products/:productId/versions.
 * Get the list of versions of products.
 *
 * @async
 * @param {string} productId - Product id.
 * @returns {{versions: Array<object>, count: number}} Results.
 */
export async function getComponentVersions(productId) {
  const { body } = await agent
    .get(`/api/deploymentsVersions/component/${e(productId)}`);

  return (body || []).map(mapVersionFromServer);
}
