import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react/hoc/connectProxy';
import {
  compose, lifecycle, branch, renderNothing,
} from 'recompose';
import { Helmet } from 'react-helmet';

import { historyShape } from 'shapes/router';
import { getProduct } from 'redux/products/actions';
import { getSecurityData } from 'redux/security/actions';
import { getComponentVersions } from 'redux/versions/actions';
import {
  selectDetailedProductsMap,
} from 'redux/products/selectors';
import { postProductHistory } from 'redux/histories/actions';
import { selectDomainsMap, selectCommunitiesMap } from 'redux/communities/selectors';
import { getDomain } from 'redux/communities/actions';
import { ACTIONS as PRODUCT_ACTIONS } from 'redux/products/constants';
import { domainShape, communityShape } from 'shapes/community';
import { productShape } from 'shapes/product';

import withRouteParams from 'react/hoc/withRouteParams';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import { selectSecurityData } from 'redux/security/selectors';
import { securityShape } from 'shapes/security';
import ComponentPage from 'react/pages/component/componentPage/ComponentPage';
import { Redirect } from 'react-router';
import { formatRoute } from 'react-router-named-routes';
import { selectComponentVersionsList } from 'redux/versions/selectors';
import { versionShape } from 'shapes/version';
import messages from './component.messages';
import classNames from './component.module.scss';
import ROUTES from '../../routes';
import { PRODUCT_VIEW } from '../../../config/constants';

const mapRouteParamsToProps = (params => ({
  componentId: params.componentId,
  view: params.view,
}));

const enhancer = compose(
  withRouteParams(mapRouteParamsToProps),
  connect(
    (state, props) => {
      const component = selectDetailedProductsMap(state)[props.componentId];
      const domain = selectDomainsMap(state)[component?.domainId];
      const community = selectCommunitiesMap(state)[domain?.community?.id];
      const security = selectSecurityData(state, props.componentId);
      const versions = selectComponentVersionsList(state, props.componentId);

      return {
        component,
        domain,
        community,
        security,
        versions,
      };
    },
    {
      getProduct,
      postProductHistory,
      getDomain,
      getSecurityData,
      getComponentVersions,
    },
  ),

  // Load product on mount, and display nothing until loaded.
  compose(
    lifecycle({
      /** Load product on mount. */
      async componentDidMount() {
        const action = await this.props.getProduct(this.props.componentId);
        await this.props.getSecurityData(this.props.componentId);
        await this.props.getComponentVersions(this.props.componentId);

        // If the product exists, add the product to the user history and get the community/domain.
        if (action.type === PRODUCT_ACTIONS.GET_PRODUCT_SUCCESS) {
          this.props.postProductHistory(this.props.componentId);
          if (!this.props.domain) {
            this.props.getDomain(action.product.domainId, { populateCommunity: true });
          }
        }
      },
    }),

    // If no product, render nothing (aka does not render the Product View).
    branch(
      props => !props.component,
      renderNothing,
    ),
  ),
);

class Component extends React.PureComponent {
  static propTypes = {
    /** From withRouteParams / Id of the product to fetch. */
    componentId: PropTypes.string.isRequired,
    /** Fetched product. */
    component: productShape,
    /** Product domain. */
    domain: domainShape,
    /** Product domain community. */
    community: communityShape,
    history: historyShape.isRequired,
    view: PropTypes.string,
    security: securityShape,
    versions: PropTypes.arrayOf(versionShape),
  };

  static defaultProps = {
    component: null,
    domain: null,
    community: null,
    view: '',
    security: null,
    versions: [],
  };

  /**
   * Renders the component.
   *
   * @returns {object} - The React element.
   */
  render() {
    const {
      component,
      domain,
      community,
      view,
      security,
      versions,
    } = this.props;

    // Switch view in case of trying to load a product in a component view
    if (!component.parentDigitalProduct) {
      return (
        <Redirect to={
        formatRoute(
          ROUTES.PRODUCT.PRESENTATION,
          { productId: component.id, view: PRODUCT_VIEW.OVERVIEW })
      }
        />
      );
    }

    return component ? (
      <ApplicationLayout mainClassName={classNames.container}>
        {
          component?.name && (
            <FormattedMessageChildren
              {...messages.TITLE}
              values={{ componentName: component.name }}
            >
              {message => (
                <Helmet>
                  <title>{message}</title>
                </Helmet>
              )}
            </FormattedMessageChildren>
          )
        }
        <ComponentPage
          component={component}
          domain={domain}
          community={community}
          view={view}
          security={security}
          versions={versions}
        />
      </ApplicationLayout>
    ) : null;
  }
}

export default enhancer(Component);
