import * as VersionService from 'services/versions';
import { ACTIONS } from './constants';

export const GET_COMPONENT_VERSIONS = () => ({
  type: ACTIONS.GET_COMPONENT_VERSIONS,
});

export const GET_COMPONENT_VERSIONS_SUCCESS = (componentId, versions) => ({
  type: ACTIONS.GET_COMPONENT_VERSIONS_SUCCESS,
  componentId,
  versions,
});

export const GET_COMPONENT_VERSIONS_FAILURE = error => ({
  type: ACTIONS.GET_COMPONENT_VERSIONS_FAILURE,
  error,
});

export const getComponentVersions = componentId => async (dispatch) => {
  dispatch(GET_COMPONENT_VERSIONS());

  try {
    const versions = await VersionService.getComponentVersions(componentId);
    return dispatch(GET_COMPONENT_VERSIONS_SUCCESS(componentId, versions));
  } catch (err) {
    return dispatch(GET_COMPONENT_VERSIONS_FAILURE(err));
  }
};
